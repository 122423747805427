.map-wrapper {
    // width: calc(100% - 360px);
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
.map-loading {
    height: 100%;
    .ant-spin-container {
        height: 100%;
    }
}