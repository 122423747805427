.time-picker {
    display: flex;
    align-items: center;
    &__label {
        margin-right: 6px;
        font-size: 14px;
        color: #fff;
        line-height: 14px;
        span {
            margin-left: 10px;
        }

    }
    &__layout {
        width: 267px;
        height: 36px;
        background: #FFFFFF;
        box-shadow: -1px 0px 7px 0px rgba(10, 10, 10, 0.27);
        border-radius: 8px;
        color: #262626;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .time-picker-component {
        width: 269px;
        height: 36px;
        border-radius: 8px;
        background: #fff!important;
        border: none;
        color: #262626;
    }
    .ant-picker-input > input {
        color: #00F1E3!important;
        text-align: center;
    }
    .date-icon {
        display: block;
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        background-image: url("../../assets/img/date.png");
      
    }
}

