* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    background-color: #232b45;
}

body {
    height: 100vh;
    overflow: hidden;
}

.content {
    height: calc(100vh - 50px);
    display: flex;

    &__side {
        //width: 400px;
    }

    &__main {
        flex: 1;
        position: relative;
    }
}

.float-bar {
    position: absolute;
    z-index: 9999;
    right: 30px;
    top: 172px;
}

.flow-picker-bar {
    position: absolute;
    right: 30px;
    top: 88px;
}

.flow-weight {
    width: 300px;
    height: 60px;
    background: #fff;
   
box-shadow: -1px 0px 7px 0px rgba(10, 10, 10, 0.27);
    border-radius: 6px;
    padding-top: 22px;
    display: flex;
    justify-content: center;
    //align-items: center;
    position: absolute;
    right: 30px;
    bottom: 30px;
    &__content {
        width: 260px;
    }
    &__bar {
        width: 100%;
        height: 6px;
        background: linear-gradient(
            90deg,
            #03e0e1 0%,
            #3af802 19%,
            #f9d300 38%,
            #f98d00 59%,
            #f92f00 80%,
            #f90a90 100%
        );
        border-radius: 6px;
        margin-bottom: 8px;
        position: relative;
        &__dot {
            height: 10px;
            width: 10px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
            border: 1px solid #ffffff;
            border-radius: 50%;
            top: -2px;
            &--low {
                background: #09dec7;
                position: absolute;
                left: -5px;
            }
            &--high {
                background: #f80c80;
                position: absolute;
                right: -5px;
            }
        }
    }
    &__step {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        color: #898b92;
    }
    &__num {
        line-height: 14px;
        &:first-child {
            margin-left: -4px;
        }
        &:last-child {
            margin-right: -4px;
        }
    }
}


.hot-tips {
    width: 360px;
    height: 138px;
    box-sizing: border-box;
    background: #0F1726;
    border-radius: 6px;
    opacity: 0.8;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -180px;
    margin-top: -75px;
    z-index: 9999;
   
    &__close {
        text-align: right;
        padding: 2px 6px;
    }
    &__content {
        padding: 0 27px;
        &__title {
            font-size: 14px;
            color: #02E0D5;
            line-height: 14px;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #FBFBFC;
        line-height: 20px;
    }
}